* {
    box-sizing: inherit;
}

.td-class {
    display: table-cell;
    height: 50px;
    margin: 1;
    width: 200px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    max-height: .6em;
    line-height: 1.8em;
    min-width: 150px;
    max-width: 150px;
    align-items: flex-start;
    padding: 16px;
    border-bottom: 5px solid #f1f1f1;
}

.td-class div {
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    align-items: flex-start;
}
.th-class-selectable:hover {
    background-color: #f1f1f1;
}

.th-class {
    display: table-row;
    height: 100%;
    margin: 1;
    width: 100%;
    font-size: .8em;
    height: 100px;
    text-overflow: ellipsis;
    border-color: #f1f1f1;
    border-bottom: 5px solid #f1f1f1;
}


.tbody-class {
    display: table-row-group;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: collapse;
}

.thead-class {
    display: table-header-group;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: collapse;
}

.table-class {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.scrollbar-style::-webkit-scrollbar-track {
    height: 8px;
    background-color: #f1f1f1;
}